import * as Yup from 'yup'
import { useEffect, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { AuthPopinProps } from '../../components/molecules/AuthPopin'
import { imageMocks } from '../../components/atoms/Picture/mocks'
import { formikFormMocks } from '../../components/form/FormikForm/mock'
import { formikFieldMocks } from '../../components/form/FormikField/mocks'
import { actionButtonMock } from '../../components/atoms/ActionButton/mocks'
import { Icons } from '../../components/atoms/Icon'
import { ActionButtonVariant } from '../../components/atoms/ActionButton/styled'
import Router, { routes } from '../../routes/Router'
import { useServiceRequest } from '../../hooks/useServiceRequest'
import { actions, selectors } from '../../redux'
import tracking from '../../tracking'
import { themes } from '../../theme'

import {
  getPasswordInputPropsForLogin,
  getUsernameInputProps,
} from './passwordRules'

type UseAuthPopinProps = {
  openAuthPopin: boolean
  overrideCloseHandler?: () => void
}

// Sorry... I did not find a proper way to style it
// so that some header element does not overlap with the overlay of the popin (the z-indexes are correctly set but it does not work)
const changeHeaderIndexes = (open: boolean) => {
  const header = document.getElementById('mainHeader')
  if (header) {
    header.style.zIndex = open
      ? themes.theme.zIndexes.default.toString()
      : themes.theme.zIndexes.navigation.toString()
  }
}

const useAuthPopin = (props: UseAuthPopinProps): AuthPopinProps => {
  const { openAuthPopin, overrideCloseHandler } = props
  const { t } = useTranslation()
  const [open, setOpen] = useState(openAuthPopin)
  const dispatch = useDispatch()
  const fullAuthPopinOpen = useSelector(selectors.auth.fullAuthPopinOpen)

  useEffect(() => {
    setOpen(openAuthPopin || fullAuthPopinOpen)
    changeHeaderIndexes(openAuthPopin)
  }, [openAuthPopin, fullAuthPopinOpen])

  const onClose = useCallback(() => {
    if (overrideCloseHandler) {
      overrideCloseHandler()
      return
    }
    setOpen(false)
    changeHeaderIndexes(false)
    dispatch(actions.auth.loginReset())
    dispatch(actions.auth.setFullAuthPopinOpen(false))
  }, [dispatch, overrideCloseHandler])

  // @NOTE: this can't work because R5 triggers a redirect before the login service finishes
  const onLoginComplete = useCallback(() => {
    setOpen(false)
    tracking.authPopin.direct()
  }, [])

  const [login, loginHandler] = useServiceRequest(
    selectors.auth.login,
    actions.auth.loginRequest,
    null,
    onLoginComplete
  )

  useEffect(() => {
    return () => {
      dispatch(actions.auth.loginReset())
      dispatch(actions.auth.setFullAuthPopinOpen(false))
    }
  }, [dispatch])

  const [, socialLoginHandler] = useServiceRequest(
    selectors.auth.socialLogin,
    actions.auth.socialLoginRequest,
    actions.auth.socialLoginReset,
    onLoginComplete
  )

  return {
    isOpen: open,
    logoProps: {
      ...imageMocks,
      images: [
        {
          src: '/static/assets/images/common/logo-110-min.png',
          size: 400,
        },
        {
          src: '/static/assets/images/common/logo-110-min.webp',
          size: 400,
        },
      ],
      alt: t('seo_logo_alt'),
    },
    closeHandler: onClose,
    title: t('auth_popin_title'),
    login: {
      title: t('auth_popin_login_title'),
      formikForm: {
        ...formikFormMocks.basic,
        initialValues: {
          email: '',
          password: '',
        },
        validationSchema: Yup.object().shape({
          email: Yup.string().email().required(t('required_field')),
          password: Yup.string().required(t('required_field')),
        }),
        onSubmit: loginHandler,
        validateOnChange: false,
      },
      formikFieldEmailProps: {
        ...formikFieldMocks.basic,
        name: 'email',
        label: t('auth_popin_login_email_label'),
        placeholder: '',
        inputProps: {
          ...getUsernameInputProps(),
        },
      },
      formikFieldPasswordProps: {
        ...formikFieldMocks.basic,
        type: 'password',
        name: 'password',
        label: t('auth_popin_login_password_label'),
        placeholder: '',
        inputProps: {
          ...getPasswordInputPropsForLogin(),
        },
      },
      linkResetPasswordProps: {
        label: t('auth_popin_login_forgot_password'),
        href: Router.getRouteUrl(routes.requestPassword.name),
      },
      submitButtonText: t('auth_popin_login_button'),
      errors: login?.errors
        ? {
            messages: login.errors,
          }
        : undefined,
    },
    signUp: {
      title: t('auth_popin_signup_title'),
      signUpButtonProps: {
        ...actionButtonMock,
        label: t('auth_popin_signup_button'),
        href: Router.getRouteUrl(routes.signup.name),
        onClick: () => tracking.authPopin.signup(),
      },
    },
    social: {
      title: t('auth_popin_social_title'),
      actionFacebookButtonProps: {
        ...actionButtonMock,
        label: t('header_auth_popin_facebook'),
        iconPosition: 'left',
        iconProps: {
          icon: Icons.socialFacebook,
          width: 24,
          height: 24,
        },
        onClick: () => {
          tracking.authPopin.direct('facebook')
          socialLoginHandler({
            provider: 'facebook',
          })
        },
      },
      actionGoogleButtonProps: {
        ...actionButtonMock,
        label: t('header_auth_popin_google'),
        variant: ActionButtonVariant.secondary,
        iconPosition: 'left',
        iconProps: {
          icon: Icons.google,
          width: 24,
          height: 24,
        },
        onClick: () => {
          tracking.authPopin.direct('google')
          socialLoginHandler({
            provider: 'google',
          })
        },
      },
    },
  }
}

export default useAuthPopin
